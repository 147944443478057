<template>
  <section id="content" ref="checkContent">
    <PageTitle :title="$t('intrastats.check.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedModule">
        <div class="col-lg-12">
          <Check></Check>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

import { useGenericPage } from '@fwk-client/composables/useGenericPage';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';
import Header from '../panels/Header.vue';
import Check from '../panels/Check.vue';

import { useModuleAdmin } from '../../composables/useModuleAdmin';

export default defineComponent({
  props: {
  },
  components: {
    PageTitle,
    Check,
    Header
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const checkContent:Ref<HTMLElement|null> = ref(null);

    const genericPage = useGenericPage(props, context);
    const { modules, selectedModule } = useModuleAdmin(props, context);

    const breadcrumbs = [{
      label: app.$t('home.dashboard')
    },
    {
      label: app.$t('intrastats.title')
    }]

    onMounted(() => {
      enableIbox(checkContent.value!);
    })

    onBeforeUnmount(() => {
      disableIbox(checkContent.value!);
    })

    return {
      checkContent,
      breadcrumbs,
      selectedModule
    }
  }
});
</script>