import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useModuleAdmin } from "./useModuleAdmin";
import { useConfigAdmin } from "./useConfigAdmin";
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';

interface CheckAdminInput {
}

export function useCheckAdmin(props:CheckAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedModule, callModuleAdmin, emitModuleAdmin } = useModuleAdmin(props, {emit})
  const { config } = useConfigAdmin(props, {emit});

  const listCompanies:Ref<any[]> = ref([]);

  const checkForm:Ref<any> = ref({
    party: {
      id: '',
      name: ''
    },
    email: '',
    declarationTypeCode: '1',
    file: null
  });

  const updateListCompanies = async () => {
    // We need to get the list of available companies for the current logged in user
    var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/list-companies');
    if(response.companies) {  
      listCompanies.value = response.companies;
    }
  }

  const checkFile = async (options?:api.ApiOptions) => {
    var result:any = {
      validated: false
    }

    var input = {
    }

    var formData = new FormData();
    if(checkForm.value.email != undefined && checkForm.value.email != '') {
      formData.append("email", checkForm.value.email);
    }
    formData.append("partyName", checkForm.value.party.name);
    formData.append("declarationTypeCode", checkForm.value.declarationTypeCode);
    if(checkForm.value.file != null) {
      var size = (checkForm.value.file! as File).size;
      if(size > 1000000) {
        store.commit('messages/' + messagesTypes.mutations.ADD_GENERIC_MESSAGE, {
                message: app.$t('intrastats.check.errors.fileToBig'),
                type: MessageTypes.ERROR
            });
        return;
      }
      formData.append("file", checkForm.value.file!, (checkForm.value.file! as File).name);  
    }

    try {
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/check', input, formData, options);
      if(response.checked) { 
        checkForm.value.file = null;
        result = {
          ...response
        }
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  } 

  watch(
    config,
    (val:any, oldVal:any) => {
      checkForm.value.party.name = val.defaultValues.party.name;
    },
    { deep: true }
  )

  watch(
    () => checkForm.value.party.name,
    (val:any, oldVal:any) => {
      let party = config.value.form.party.find((element:any) => element.name == val);
      if(party) {
        checkForm.value.party.id = party.id;
      }
    },
    { deep: false }
  )

  return {
    listCompanies,
    checkForm,
    updateListCompanies,
    checkFile
  }
  
}