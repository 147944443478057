<template>
  <div class="ibox">
    <div class="ibox-content p-md">

      <Messages :messages="errors" type="error"></Messages>
      <Messages :messages="successes" type="success"></Messages>

      <form role="form" name="checkForm" @submit="onFormSubmit">
        <div v-if="isEmailEnabled" class="form-group row">
            <label for="checkFileEmail" class="col-lg-3 col-form-label">{{ $t('intrastats.check.form.email') }}</label>
            <div class="col-lg-9">
              <input id="checkFileEmail" type="email" :placeholder="$t('intrastats.check.form.email_placeholder')" class="form-control" v-model="checkForm.email" required>
            </div>
        </div>
        <div class="form-group row">
            <label for="generateXMLPartyName" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.party-name') }}</label>
            <div class="col-lg-9">
              <input v-if="config.form.party.length == 1" id="generateXMLPartyName" type="text" :placeholder="$t('intrastats.generate.form.party-name_placeholder')" class="form-control-plaintext" v-model="checkForm.party.name" disabled>
              <select v-if="config.form.party.length > 1" id="generateXMLPartyName" class="form-control" v-model="checkForm.party.name" required>
                <option v-for="(party, index) in config.form.party" :key="index" :value="party.name">{{party.name}}</option>
              </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="generateXMLPartyId" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.party-id') }}</label>
            <div class="col-lg-9">
              <input id="generateXMLPartyId" type="text" :placeholder="$t('intrastats.generate.form.party-id_placeholder')" class="form-control-plaintext" v-model="checkForm.party.id" disabled>
            </div>
        </div>
        <div class="form-group row">
            <label for="generateXMLDeclarationTypeCode" class="col-lg-3 col-form-label">{{ $t('intrastats.check.form.declaration-type-code') }}</label>
            <div class="col-lg-9">
              <select id="generateXMLDeclarationTypeCode" class="form-control" v-model="checkForm.declarationTypeCode" required>
                <option value="1">{{ $t('intrastats.check.form.declaration-type-code-one') }}</option>
                <option value="2">{{ $t('intrastats.check.form.declaration-type-code-two') }}</option>
                <option value="3">{{ $t('intrastats.check.form.declaration-type-code-three') }}</option>
                <option value="4">{{ $t('intrastats.check.form.declaration-type-code-four') }}</option>
                <option value="5">{{ $t('intrastats.check.form.declaration-type-code-five') }}</option>
              </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="checkFileFile" class="col-lg-3 col-form-label">{{ $t('intrastats.check.form.file') }}</label>
            <div class="col-lg-9">
              <b-form-file
                v-model="checkForm.file"
                :state="Boolean(checkForm.file)"
                :placeholder="$t('intrastats.check.form.file_placeholder')"
                :drop-placeholder="$t('intrastats.check.form.file_drop-placeholder')"
                required
              />
            </div>
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('intrastats.check.form.button') }}</button>
      </form>
    
    </div>
  </div>
</template>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, ComputedRef} from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Messages from '@root/src/client/components/panels/Messages.vue';

import { Message } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

import { useCheckAdmin } from '../../composables/useCheckAdmin';
import { useGenerateAdmin } from '../../composables/useGenerateAdmin';

export default defineComponent({
  props: {
      
  },
  components: {
    Messages
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { checkForm, checkFile } = useCheckAdmin(props, context);
    const { config } = useGenerateAdmin(props, context);

    // We disable the email by default as we are using the user email
    const isEmailEnabled = false;

    const errors:Ref<Message[]> = ref([]);
    const successes:Ref<Message[]> = ref([]);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=checkForm] button' );
      laddaSubmit = Ladda.create(button!);
    })

    const onApiErrors = (apiErrors:Message[]) => {
      errors.value = apiErrors;
    }

    const onApiSuccesses = (apiSuccesses:Message[]) => {
      successes.value = apiSuccesses
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();
      
      // We clear the current messages
      errors.value = [];
      successes.value = [];

      laddaSubmit!.start();

      checkFile({
        errorsHandler: onApiErrors,
        successesHandler: onApiSuccesses
      }).then((response:any) => {
        laddaSubmit!.stop();
      });
    }

    return {
      isEmailEnabled,
      errors,
      successes,
      onFormSubmit,
      checkForm,
      config
    }
  }
});
</script>